import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        facts: [
            { title: 'Fullstack', text: 'Check', icon: 'check-circle', variant: 'success' },
            { title: 'Desktop / Mobile', text: 'Check', icon: 'check-circle', variant: 'success' },
            { title: 'Nerd / GameDev', text: 'Check', icon: 'controller', variant: 'success' },
            { title: 'Office Dog', text: 'Nala', icon: 'heart', variant: 'danger' },
        ],

        projects: [
            {
                title: 'GTM-Doc',
                text: 'Obwohl der Google Tag Manager damit wirbt auch für Nicht-Entwickler_Innen leicht verständlich zu sein, so bedarf es doch einer gewissen Einarbeitungszeit, bis man das Interface und die Zusammenhänge versteht. Diese Zeit kann und will aber nicht immer investiert werden. Deshalb habe ich GTM Doc entwickelt. Webseite aufrufen, in Google einloggen und Container auswählen - fertig!',
                link: 'https://gtm.nick-hat-boecker.de/',
                image: 'gtm-doc.jpg',
            },
            {
                title: 'Lyricly',
                text: 'Ich singe für mein Leben gern in meiner Studiokabine. Gerne auch einfach Mal Karaoke. Statt nach den Liedtexten und YouTube Videos immer wieder händisch zu googlen, habe ich eine Webseite entwickelt, die beides vereint. Basis dafür sind eine Vue3 App und eine Appwrite Datenbank.',
                link: '',
                image: 'lyricly.jpg',
            },
            {
                title: 'Godot Blog',
                text: 'Beim Programmieren mit der Godot Engine gibt es immer wieder Funktionen, die mir entfallen. Deshalb habe ich kurzerhand ein simples CMS samt REST API und einer Vue3 App in einem Docker Container angelegt. Mit der eingebauten Such-Funktion finde ich nun alles wieder!',
                link: 'https://dev.nick-hat-boecker.de/',
                image: 'godot-blog.jpg',
            },
            {
                title: 'Maßnahmen-Generator',
                text: '"Schulen alle zwei Wochen mit Trockenhefe versorgen"? Solche und weitere irrsinnige Beschlüsse generiert der Maßnahmengenerator. Als Satire auf die deutsche Corona-Politik ging die Webseite in wenigen Stunden viral und war sogar in den deutschen Top 3 Twitter Trends. Die Zeit, t3n und ntv haben online berichtet. <span class="u-text-highlight">#maßnahmengenerator</span>',
                link: 'https://nick-hat-boecker.de/corona-generator/',
                image: 'corona-generator.jpg',
            },
            {
                title: 'Shoppyli',
                text: 'Mit dieser Webapp erstelle ich spielend Einkaufslisten, plane meine Woche mit einem personalisierten Essensplan und behalte meine Lieblingsrezepte im Blick. Und das wichtigste: Meiner Frau gefällt sie auch!',
                image: 'shoppyli.jpg',
            },
            {
                title: 'Good-Boi',
                text: 'Ist es möglich ein einprägsames Spiel mit nur einer einzigen Minute Gameplay zu schaffen? Das war die Fragestellung beim One Minute Jam. Daraus resultierte das Minispiel "Good-Boi". Das war mein erster Game Jam und mein erstes abgeschlossenes Spielprojekt.',
                link: 'https://nickhatboecker.itch.io/good-boi-one-minute-jam',
                image: 'good-boi.jpg',
            },
            {
                title: 'ROAR',
                text: 'Nachdem ich Jahre lang meinen Ford Mondeo mit dem Motto "Wird schon schief gehen!" gefahren bin, möchte ich nun etwas mehr Zeit und Liebe in meinen gebrauchten Peugeot 2008 investieren. Dazu habe ich ROAR entwickelt. Mit Hilfe der App protokolliere ich verschiedene Attribute wie Tankfüllungen und Reifendruck, woraufhin sie mich an anstehende Checks und Inspektionen erinnert. Außerdem kann ich gespeicherte Routen direkt an mein Auto Navi senden.',
                image: 'roar.jpg',
            },
            {
                title: 'Deploy-it!',
                text: 'Diese electron-vue Anwendung ist für den reinen lokalen Nutzen konzipiert und erleichtert die Handhabung von Deployments auf entfernte Server. Über eine zentral gepflegte JSON Datei, kann die Liste der Projekte und deren Deploy-Befehlen dynamisch erstellt werden. Die Benutzer_Innen werden mit Hilfe von Farben und Icons über den aktuellen Status ihres Deployments informiert.',
                link: 'https://github.com/NickHatBoecker/deploy-it',
                image: 'deploy-it.jpg',
            },
            {
                title: 'Sublime Plugin: Copy Filepath With Line Numbers',
                text: 'Außerhalb von GitHub und GitLab ist es schwierig auf eine Zeile Code in einer Datei zu verweisen. Mit diesem Sublime Text Plugin kann der absolute oder relative Pfad der geöffneten Datei, inklusive Zeilennummer, in die Zwischenablage kopiert werden.',
                link: 'https://packagecontrol.io/packages/Copy%20Filepath%20With%20Line%20Numbers',
                image: 'sublime-copy-filepaths.jpg',
            },
            {
                title: 'Sublime Plugin: Save Copy As',
                text: 'Sublime Text ist ein toller Editor. Abgesehen von einer Funktionalität, die ich selbst in keinem Plugin finden konnte. Also habe ich ein eigenes geschrieben. Wer seine Dateien nicht mit git versioniert, der erinnert sich ggf. noch an die guten alten Backup-Dateien. Mit dem Plugin "Save copy as" kann man die gerade geöffnete Datei unter einem anderen Namen abspeichern, ohne die aktuelle Datei zu überschreiben.',
                link: 'https://packagecontrol.io/packages/Save%20Copy%20As',
                image: 'sublime-save-as.jpg',
            },
            {
                title: 'Alexa Pocket Dex',
                text: 'Mitten in einem hitzigen Kampf und du weißt nicht mehr welcher Typ gegen Gift effektiv ist? Kein Problem! Frag einfach Alexa und behalte die Oberhand über deine Rival_Innen. Der perfekte Begleiter für die Pokémon™ Videospiel-Reihe von Nintendo.',
                link: 'https://www.amazon.de/dp/B09983FH75/',
            },
            {
                title: 'Perry Pocket',
                text: 'Wer kennt es nicht? Unzählige Excel-Listen mit Zahlen und Berechnungen. Wie viel gibt man im Monat für was aus und wie viel bleibt vom Gehalt für den eigenen Luxus über? In Perry-Pocket hält man sein monatliches Budget und die Ausgaben fest. Der Name „Perry Pocket“ kommt vom englischen Wort „Pocket money“. Kennt jemand außerdem den gleichnamigen Schnabeltier-Agenten? <span class="u-text-highlight">Peeeerry~</span>',
                image: 'perry-pocket.jpg',
            },
            {
                title: 'My year in pixels',
                text: 'Das Konzept „My Year in Pixels“ wurde von der Userin Camille (<a href="https://www.instagram.com/passioncarnets/" target="_blank" rel="noopener" title="Instagram-Profil von passioncarnets öffnen (Link öffnet in einem neuen Fenster)">@passioncarnets</a>) entworfen. Dabei notiert man pro Monat für jeden Tag ein Kästchen und füllt dieses mit einer Farbe. Am Ende des Jahres hat man so eine Übersicht, wie man sich über das Jahr weg gefühlt hat. Diese App basiert auf VueJS und speichert die Kästcheninhalte in den Local Storage.',
                link: '',
                image: 'year-in-pixels.jpg',
            },
            {
                title: 'Retro Respect',
                text: 'Inspiriert durch einen Beitrag auf Twitter habe ich einen minimalistischen Pixel-Nachricht-Generator gebaut: Retro Respect. Ihr könnt damit euren Liebsten eine Nachricht hinterlassen und habt die Wahl zwischen fünf verschiedenen Themes. Der Name Retro Respect kommt daher, dass man mit diesen Nachrichten ruhig mal öfter etwas Nettes im Internet verbreiten sollte <span class="u-text-highlight">#blacklivesmatter</span>. Eckige Grafiken und die Pixel-Schrift <a href="https://www.dafont.com/04b-03.font" target="_blank" rel="noopener" title="Zur Schriftart-Webseite (Link öffnet in neuem Fenster)">04b03</a> runden den Retro-Look ab.',
                link: 'https://retro-respect.nick-hat-boecker.de/',
                image: 'retro-respect.jpg',
            },
            {
                title: 'Host-it! Virtualhost Manager für MacOS',
                text: 'Ich war auf der Suche nach einer einfachen Möglichkeit meine Virtualhosts unter MacOS zu managen. Da ich nicht fündig wurde, habe ich Host-it geschaffen. Schnell und einfach neue Virtualhosts hinzufügen ist damit ein Kinderspiel. Dank Electron-Vue lässt sich die App ohne Installation in den Applications Ordner von MacOS ablegen und ist sofort startklar – easy und minimalistisch.',
                link: 'https://github.com/NickHatBoecker/host-it',
                image: 'host-it.jpg',
            },
            {
                title: 'Bookmark-it!',
                text: 'Chrome Lesezeichen bieten nicht genügend Freiheit, Pocket hat nicht immer sauber gearbeitet, Qlearly hat meine Lesezeichen alle gelöscht und war danach nur noch verbugged und Google hat schon genug Daten von mir. Mit Bookmark-it hoste ich meinen eigenen Lesezeichen Manager und habe die volle Kontrolle über meine gespeicherten Links. Die Anwendung besteht aus drei Teilen: Der VueJS App, der Symfony API und der optionalen Chrome Extension.',
                image: 'bookmarks.jpg',
            },
            {
                title: 'Samsung Galaxy Nerd Watchface',
                text: 'Anders als die meisten modernen Smartwatches baut die Samsung Galaxy Watch nicht auf Wear OS, sondern auf dem Betriebssystem Tizen auf. Dank der angebotenen Web-API haben so auch Webentwickler_Innen das Vergnügen Applikationen für die Smartwatch zu programmieren. Ich habe hierfür ein Watchface implementiert, angelehnt an mein Lieblingsfandom Digimon und das Digivice der ersten Staffel.',
                link: 'https://github.com/theskyliner/TizenDigimonWatchface',
                image: 'digiwatch.jpg',
            },
            {
                title: 'Animexx RPG Ticker',
                text: 'Der Animexx RPG Ticker ist eine Chrome Extension, die den Benutzer_Innen via Push-Benachrichtigung über neue Postings in einem ausgewählten Animexx-RPG informiert. Mit der Angabe eines Intervals wird die Prüfung automatisch im Hintergrund oder wahlweise manuell über einen Button angestoßen. Vielen Dank an den Animexx e.V. für das Bereitstellen der Grafiken, der API und den netten Support.',
                link: 'https://chrome.google.com/webstore/detail/animexx-rpg-ticker/nhjhionoaglnljohhjkaiepoiephkidi?authuser=1',
            },
        ],
    },
})
