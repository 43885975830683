<template>
    <div class="socials">
        <a href="https://nick-hat-boecker.de/linktree" class="h6 mr-4" target="_blank" rel="noopener" title="Gehe zu meinem Linktree">
            <img class="socials__item" src="../assets/linktree.svg" alt="Linktree Icon" width="30px" height="30px" />
        </a>
        <a href="https://twitter.com/NickHatBoecker" class="h6 mr-4" target="_blank" rel="noopener" title="Gehe zu meinem Twitter Profil">
            <img class="socials__item" src="../assets/tw1tter.svg" alt="Twitter Logo" width="30px" height="30px" />
        </a>
        <a href="https://github.com/NickHatBoecker" class="h6 mr-4" target="_blank" rel="noopener" title="Gehe zu meinem GitHub Profil">
            <img class="socials__item" src="../assets/github.svg" alt="GitHub Logo" width="30px" height="30px" />
        </a>
    </div>
</template>

<script>
export default {
    name: 'Socials',
}
</script>
