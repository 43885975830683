import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/impressum',
        name: 'imprint',
        component: () => import(/* webpackChunkName: "imprint" */ '../views/Imprint.vue')
    },
    {
        path: '/datenschutz',
        name: 'dataprivacy',
        component: () => import(/* webpackChunkName: "data-privacy" */ '../views/DataPrivacy.vue')
    },
    {
        path: '/vita',
        name: 'vita',
        component: () => import(/* webpackChunkName: "vita" */ '../views/Vita.vue'),
        meta: {
            hideSocials: true,
            hideFooter: true,
            hideNavigation: true,
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
})

export default router
