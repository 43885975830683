<template>
    <nav class="row justify-content-center navigation">
        <div class="col-12 col-sm-6 text-center linklist p-0">
            <router-link :to="{ name: 'home' }" class="link">Start</router-link>
            <a class="link" href="mailto:kontakt@nick-hat-boecker.de">Kontakt</a>
            <router-link :to="{ name: 'imprint' }" class="link">Impressum</router-link>
            <router-link :to="{ name: 'dataprivacy' }" class="link">Datenschutz</router-link>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'Navigation',
}
</script>
