import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
import store from './store'
import Aos from 'aos/dist/aos';
import router from './router'

Aos.init();

var VueScrollTo = require('vue-scrollto');

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueScrollTo)

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
